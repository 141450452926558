nav {
    height: 48px;
    border-bottom: .5px solid var(--separator);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: var(--background-blur);
    backdrop-filter: blur(16px);
    will-change: transform;
    width: 100vw;
    user-select: none;
    transition: .1s;
}

.navigation-list {
    max-width: 1104px;
    display: flex;
    margin: 0 auto;
    padding: 0 32px;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;
    height: 100%;
}

.theme-toggle {
    margin-bottom: -1px;
}

.theme-toggle-ru {
    margin-bottom: 1px;
}

.navigation-item {
    line-height: 48px;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 600;
    height: 48px;
    overflow: hidden;
}

.navigation-item a {
    padding: 0 8px;
    text-decoration: none;
}

@media(hover: hover) {
    .navigation-item:hover {
        background-color: var(--gray100);
    }
    
    .navigation-item a:hover {
        color: var(--dark-blue);
        text-decoration: none;
    }
}

.selected-item {
    display: none;
    padding: 3px 0;
    height: 10px;
    background-color: var(--blue);
    margin-top: -3px;
    border-radius: 3px;
}

.isActive .selected-item {
    display: block;
}

.moon {
    max-height: 20px;
    max-width: 19px;
    min-height: 20px;
    min-width: 19px;
    -webkit-transform: scaleY(.95);
    transform: scaleY(.95);
}

.sun {
    max-height: 19px;
    max-width: 19px;
    min-height: 19px;
    min-width: 19px;
    -webkit-transform: scaleY(.95);
    transform: scaleY(.95);
}

@media (max-width: 640px) {
    .navigation-list {
        padding: 0 8px;
    }

    .navigation-item {
        font-size: 1rem;
    }

    .navigation-list {
        column-gap: 4px;
    }
}