.toon {
    margin-top: -48px;
    font-size:0;
}

.toon img {
    margin-top: 0;
}

.container_open_more {
    cursor: pointer;
    color: white;
    position: absolute;
    bottom: 10px;
    font-size: 15px;
    background-color: var(--blue);
    height: 30px;
    width: 80px;
    padding: 2px 60px;
    box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.27);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    left: 50%;
    transform: translate(-50%, -40%);
    z-index: 4;
    overflow: hidden;
}


.container_open_more::before {
    content: "";
    position: absolute;
    top: 0;
    left: 5%;
    width: 80%;
    height: 68px;
    background: linear-gradient(90deg, rgba(62, 100, 255, 0), rgba(255, 255, 255, 0.5), rgba(62, 100, 255, 0));
    animation: flareAnimation linear 5s infinite;
    z-index: 1;
}



@keyframes flareAnimation {
    0% {
        left: -500%;
    }
    100% {
        left: 500%;
    }
}

.img_container_height {
    overflow: hidden;
    margin-bottom: 30px;

}



.img_container_open {
    position: absolute;
    font-size: 20px;
    right: calc(50% - 16px);
    bottom: 10px;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    transition: transform 1.5s ease-in-out;
    z-index: 3;
}

.img_container_open::before {
    content: '';
    position: absolute;
    width: 70%;
    height: 70%;
    border-radius: 50%;
    background: radial-gradient(circle, #3498db 0%, #3498db 50%, transparent 100%);
    opacity: 0;
    animation: ripple 1.5s linear infinite;
}


.svg_arrow {
    width: 33px;
    height: 33px;
    cursor: pointer;
    background-color: white;
    border-radius: 100px;
    transition: margin 1s ease;
    transform: rotate(0deg);
}

.float-up {
    transform: translateY(-5px);
}

.float-down {
    transform: translateY(-15px);
}

@keyframes ripple {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(3);
        opacity: 0;
    }
}


.svg_arrow_close {
    transform: rotate(180deg);
}

@media (max-width: 640px) {
    .toon {
        margin: -48px -16px 0 -16px;
        width: calc(100% + 32px);
    }
}

.active_img {
    max-height: 0px;
    transition: max-height 1s ease;
}

.hidden_img {
    max-height: 0px;
    transition: max-height 1s ease;
}

.scrollBtnContainer {
    position: fixed;
    bottom:3vh;
    right: 3vh;
    display: flex;
    z-index: 1;
    transition: .2s;

}

.scrollBtnContainer button {
    background-color: var(--blue);
    border: none;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
    cursor: pointer;
    width: 54px;
    height: 54px;
    animation: btnAnimation infinite 2s;
}

.scrollBtnContainer path {
    fill: var(--scrolBtnSvgColor);
}

@keyframes btnAnimation {
    /*0% {*/
    /*    box-shadow: 0 0 10px var(--scrolBtnColor);*/
    /*}*/

    /*50% {*/
    /*    box-shadow: 0 0 20px var(--scrolBtnColor);*/
    /*}*/

    /*100% {*/
    /*    box-shadow: 0 0 10px var(--scrolBtnColor);*/
    /*}*/

    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);

    }

    100% {
        transform: scale(1);

    }
}

@media (max-width: 640px) {
    .scrollBtnContainer button {
        width: 46px;
        height: 46px;
    }

}




.scrollBtnContainer button:hover {
    opacity: .8;
}

.scrollBottomRef {
    position: absolute;
    width: 1px;
    height: 1px;
    bottom: 70px;
}

@media (max-width: 640px) {
    .scrollBtnContainer {
        bottom: 1.5vh;
        right: 1.5vh;
    }
}
