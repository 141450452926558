h1 {
    font-size: 2.625rem;
    line-height: 1.095;
    font-weight: 800;
    margin-top: 32px;
    color: var(--gray800);
}

h2 {
    font-size: 1.625rem;
    line-height: 1.077;
    color: var(--gray800);
    font-weight: 600;
}

h3 {
    font-size: 1.386rem;
    line-height: 1.06;
    color: var(--gray800);
    font-weight: 600;
}

h4 {
    font-size: 1rem;
    line-height: 1.06;
    font-weight: 600;
}

p {
    font-size: 1rem;
    line-height: 1.5;
}

p + p {
    margin-top: 12px;
}

b, strong {
    font-weight: 600;
}

.leed {
    font-size: 1.625rem;
    color: var(--gray500);
    font-weight: 600;
    line-height: 1.2;
}

.-caption {
    font-size: .75rem;
}

.-center {
    text-align: center;
}

.-gray {
    color: var(--gray500);
}

@media (max-width: 640px) {
    h1 {
        font-size: 2.2rem;
        line-height: 1.095;
    }

    .leed {
        font-size: 1.38rem;
        color: var(--gray500);
        font-weight: 600;
        line-height: 1.2;
    }
}