.faq-category-section {
    margin-top: 32px;
}

.faq-category-question {
    margin-top: 24px;
}

.faq-category-body {
    margin-top: 16px;
}