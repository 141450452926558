/* newsBlock */

h2.news-block-title {
    margin-top: 12px;
}

.news-block-subtitle {
    width: 100%;
    margin: 8px 0 0 0;
    line-height: 1.2;
}

.news-block-title-active {
    color: var(--fontColor);
    text-decoration: underline;
}



.news-block-img {
    width: 100%;
    position: relative;
    margin: 0;
}

.news-block-dummy {
    padding-top: calc(100% * .618);
}

.news-block-date {
    color: var(--gray500);
    font-size: .875rem;
    margin-top: 8px;
}

.news-block-soon {
    position: absolute;
    width: 50px;
    height: 32px;
    top: 8px;
    right: 8px;
    z-index: 90;
}



@media (max-width: 640px) {
    .cell.news-block-cell {
        margin-top: 24px;
    }
}