.gallery-disable-scroll {
    overflow: hidden;
}

.gallery-enable-scroll {
    overflow: auto;
}

.gallery-overlay {
    background-color: rgba(0, 0, 0, 0.9);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 200;
}

.gallery-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 210;
    width: fit-content;
    height: fit-content;
}

.gallery-img-container {
    transform: rotateY(0);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}



.gallery-plug {
    max-width: 80vw;
    max-height: 80vh;
    margin-top: 0 !important;
    width: auto !important;
    aspect-ratio: 1;
    background-color: transparent;
}

.gallery-img-loading {
    background-color: rgb(0, 0, 0);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 220;
}

.gallery-mute-button {
    position: absolute;
    width: 5%;
    opacity: 0.5;
    right: 4%;
    bottom: 5%;
    margin-top: 0;
    filter: drop-shadow(0 0 20px rgba(0,0,0,0.5));
    cursor: pointer;
}

.gallery-img-container video {
    cursor: pointer;
}

.gallery-img {
    margin-top: 0 !important;
    width: 100% !important;
    object-fit: cover;
}

.gallery-button {
    margin-top: 0 !important;
    position: absolute;
    z-index: 220;
    animation-name: close-zoom-out;
    animation-duration: .3s;
    animation-timing-function: ease-in-out;
    opacity: .3;
}

.gallery-button:hover {
    animation-name: close-zoom;
    animation-duration: .1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    cursor: pointer;
}

@keyframes close-zoom {
    from {
        transform: scale(1);
        opacity: .3;
    }

    to {
        transform: scale(1.1);
        opacity: .5;
    }
}

@keyframes close-zoom-out {
    from {
        transform: scale(1.1);
        opacity: .5;
    }

    to {
        transform: scale(1);
        opacity: .3;
    }
}

.gallery-close {
    width: 32px !important;
    top: -40px;
    right: -40px;
}

.gallery-left-button {
    position: absolute;
    width: 40px !important;
    top: calc(50% - 20px);
    left: -60px;
}

.gallery-right-button {
    position: absolute;
    width: 40px !important;
    top: calc(50% - 20px);
    right: -60px;
}

.gallery-hide-to-left {
    animation-name: hide-to-left;
    animation-duration: .4s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    transform-origin: left;
}

.gallery-set-to-left {
    transform:
        perspective(2000px) rotateY(-30deg) scale(.9) translateX(-90vw);
    opacity: 0;
}

.gallery-hide-to-right {
    animation-name: hide-to-right;
    animation-duration: .3s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    transform-origin: right;
}

.gallery-set-to-right {
    transform:
        perspective(2000px) rotateY(30deg) scale(.9) translateX(90vw);
    opacity: 0;
}

@keyframes hide-to-left {
    0% {
        transform:
            perspective(2000px) rotateY(0) scale(1) translateX(0);
        opacity: 1;
    }

    100% {
        transform:
            perspective(2000px) rotateY(-30deg) scale(.9) translateX(-90vw);
        opacity: 0;
    }
}

@keyframes hide-to-right {
    0% {
        transform:
            perspective(2000px) rotateY(0) scale(1) translateX(0);
        opacity: 1;
    }

    100% {
        transform:
            perspective(2000px) rotateY(30deg) scale(.9) translateX(90vw);
        opacity: 0;
    }
}

.gallery-show-from-right {
    animation-name: show-from-right;
    animation-duration: .4s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    transform-origin: right;
}

.gallery-show-from-left {
    animation-name: show-from-left;
    animation-duration: .4s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    transform-origin: right;
}

@keyframes show-from-right {
    0% {
        transform:
            perspective(2000px) rotateY(30deg) scale(.9) translateX(90vw);
        opacity: 0;
    }

    100% {
        transform:
            perspective(2000px) rotateY(0) scale(1) translateX(0);
        opacity: 1;
    }
}

@keyframes show-from-left {
    0% {
        transform:
            perspective(2000px) rotateY(-30deg) scale(.9) translateX(-90vw);
        opacity: 0;
    }

    100% {
        transform:
            perspective(2000px) rotateY(0) scale(1) translateX(0);
        opacity: 1;
    }
}

@media (max-width: 640px) {
    .gallery-plug {
        max-width: 90vw;
        max-height: 90vh;
    }

    .gallery-close,
    .gallery-left-button,
    .gallery-right-button {
        display: none;
    }

    .gallery-mute-button {
        width: 10%;
    }

    @keyframes hide-to-right {
        to {
            transform: translateX(90vw);
            opacity: 0;
        }
    }

    @keyframes hide-to-left {
        to {
            transform: translateX(-90vw);
            opacity: 0;
        }
    }
}