.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 48px;
}

@media (max-width: 640px) {
    .row {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 0;
    }
}