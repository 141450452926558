.beta-request-form {
    margin-top: 32px;
}

.beta-request-field {
    display: block;
    margin-top: 12px;
    font-weight: 600;
    color: var(--gray800);
}

.beta-request-captcha {
    margin-top: 24px;
}

.beta-request-input {
    display: block;
    margin-top: 4px;
    width: calc(100% - 24px);
    border: 0;
    padding: 12px;
    border-left: 3px solid var(--blue);
    background-color: var(--gray100);
    font-size: 18px;
    border-radius: 4px;
    color: var(--fontColor);
}

.beta-request-textfield {
    max-width: calc(100% - 24px);
    min-width: calc(100% - 24px);
}

.beta-request-input::placeholder {
    color: var(--gray300);
}

.beta-request-button {
    color: white;
    background: var(--blue);
    border: 0;
    padding: 12px;
    border-radius: 4px;
    margin-top: 24px;
    font-size: 18px;
    cursor: pointer;
}