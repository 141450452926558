/* Blocks */

.blocks-container {
    margin-top: 48px;
}

@media (max-width: 640px) {
    .blocks-first-row {
        margin-top: -16px;
    }
}