.museum-container {
    margin: 48px auto 0 auto;
    position: relative;
}

.museum-gallery-container {
    margin: 0 32px;
}

.museum-exhibits {
    font-size: .875rem;
    color: var(--gray500);
}

@media (max-width: 640px) {
    .museum-container {
        width: 100%;
    }

    .museum-row {
        margin-top: 24px;
    }

    .-half + .-half {
        margin-top: 24px;
    }

    .museum-gallery-container {
        margin: 0;
    }
}