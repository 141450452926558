.block-big-container {
    margin: 64px 0 48px 0;
    min-height: 545px;
}

.block-big-img {
    width: 100%;
    position: relative;
    margin: 12px 0 0 0;
}

.block-big-dummy {
    padding-top: calc(100% / 12 * 6);
    will-change: transform;
}

@media (max-width: 960px) {
    .block-big-container {
        margin: 56px 0 0 0;
        min-height: 300px;
    }
}

@media (max-width: 640px) {
    .block-big-container {
        margin: 56px 0 0 0;
        min-height: 400px;
    }

    .block-big-header {
        margin: 56px 0 0 0;
    }

    .block-big-img {
        margin-top: 16px;
    }

    .block-big-dummy {
        padding-top: 100%;
    }

    .big-animation {
        overflow: hidden;
    }

    .big-animation dotlottie-player {
        width: calc(100% / 12 * 20 + 16px);
        margin-left: calc(-1 * 100% / 12 * 5 + 16px);
    }
}