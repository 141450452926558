.welcome-container {
    margin-top: 16px;
    padding: 0 16px;
    min-height: 525px;
}

.welcome-kaller {
    display: flex;
    margin-top: 4px;
}

.welcome-kaller-arabic {
    margin-right: -50px;
    gap: 20px;
}

.welcome-logo {
    position: relative;
    aspect-ratio: 1;
    width: 256px;
    min-width: 256px;
    margin: -84px -28px -34px -60px;
}

.welcome-logo-placeholder {
    border-radius: 50%;
    bottom: 21%;
    left: 24%;
    position: absolute;
    right: 25%;
    top: 29%;
}

.welcome-name {
    font-size: 5.125rem;
    line-height: .85;
    font-weight: 600;
    letter-spacing: -2px;
    margin: 10px 0 0 -4px;
}

.welcome-tagline {
    font-size: 1.23rem;
    line-height: 1;
    font-weight: 600;

}

.welcome-tagline-ru {
    margin-top: 4px;
}



.welcome-simulator {
    display: flex;
    flex-direction: row-reverse;
    max-height: 524px;
}

.welcome-img {
    width: 100%;
    margin-top: -8px;
    min-width: 220px;
}

.welcome-text {
    margin-top: 24px;
    width: 80%;
}

.welcome-text p {
    padding-left: 3px;
    font-size: 1.9rem;
    line-height: 1.2;
}

.welcome-links {
    margin-top: 48px;
}

.welcome-link {
    margin: 32px 0 0 0;
    display: flex;
    gap: 5px;
    align-items: center;
}

.welcome-link img {
    display: inline;
    width: 1.25rem;
    margin: -5px 0 0 0;
}

.welcome-additional {
    margin-top: 56px;
}



@media (max-width: 960px) {
    .welcome-container {
        padding: 0;
        min-height: 380px;
    }

    .welcome-kaller {
        display: flex;
        margin-top: -3vw;
    }
    .welcome-logo {
        width: 27vw;
        min-width: 27vw;
        aspect-ratio: 1;
        margin: -5.5vw -3vw -34px -53px;
    }

    .welcome-name {
        margin-top: 4.2vw;
        font-size: 8.5vw;
    }

    .welcome-tagline {
        font-size: 2.05vw;
    }

    .welcome-links {
         margin-top: 6vw;
    }
    .welcome-link {
        margin-top: 3vw;
    }

    .welcome-text,
    .welcome-text p {
        width: 40.6vw;
        font-size: 3.3vw;
    }
}

@media (max-width: 640px) {

    .welcome-container {
        padding: 0;
        min-height: 1080px;
    }

    .welcome-kaller {
        display: block;
        text-align: center;
        margin-top: -8px;
    }

    .welcome-kaller-arabic {
        margin-right: 0;
        gap: 0;
    }

    .welcome-logo {
        width: 70%;
        min-width: 70%;
        aspect-ratio: 1;
        margin: -12vw auto 0 auto;
    }

    .welcome-name {
        margin-top: -6vw;
        font-size: 18vw;
    }
    .welcome-tagline {
        font-size: 4.3vw;
        font-weight: 600;
        margin-top: 4px;
    }
    .welcome-tagline-ru {
        margin-top: 8px;
    }

    .welcome-text {
        text-align: center;
        margin-top: 32px;
        padding-right: 0;
        width: 100%;
    }

    .welcome-text p {
        width: 100%;
        font-size: 6.5vw;
    }

    .welcome-links {
        display: flex;
        margin-top: 24px;
        flex-direction: column;
        row-gap: 10px;
    }

    .welcome-link {
        margin: 0 auto;
        text-align: center;
        gap: 10px;
    }

    .welcome-link img {
        margin-left: -5px;
    }

    .welcome-android {
        display: none;
    }

    .welcome-link div {
        display: inline-block;
    }

    .welcome-simulator {
        display: block;
        aspect-ratio: .5;
        max-height: none;
        margin: 32px 16px 0 16px;
    }

    .welcome-img {
        margin: 0 auto;
        max-width: 80%;
    }
}