.language-item {
    margin-left: auto;
    line-height: 48px;
    white-space: nowrap;
    min-width: 40px;
    position: relative;
    height: 100%;
}

.language-button {
    border: 0;
    background-color: inherit;
    height: 100%;
    padding: 0 8px;
    cursor: pointer;
    color: var(--blue);
    min-width: 68px;
    text-transform: uppercase;
    user-select: none;
    display: flex;
    align-items: center;
}

.language-single-button {
    border: 0;
    background-color: inherit;
    height: 100%;
    padding: 0 8px;
    color: var(--blue);
    min-width: 68px;
    text-transform: uppercase;
    user-select: none;
}

@media(hover: hover) {
    .language-button:hover {
        background-color: var(--gray100);
    }
}

.language-globe {
    display: inline;
    margin-top: -2px;
    margin-right: 4px;
    width: 18px;
    float: left;
}

.language-arrow {
    display: inline;
    width: 10px;
    margin: 0 4px;
    padding-bottom: 1px;
    transform-origin: center 2px;
    transform: rotate(0);
    transition: transform .1s ease-in-out;
}

.language-arrow-rotate {
    transform: rotate(180deg);
}

.language-available {
    position: absolute;
    top: 48px;
    box-shadow: 0 8px 16px rgba(0,0,0,.1);
    border-radius: 8px;
    background-color: var(--background);
    animation-duration: 200ms;
    min-width: 190px;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    transform-origin: top center;
    will-change: transform;
    z-index: 101;
    animation-name: 'drop-down';
}

.language-available-item {
    padding: 0 16px;
    min-width: 158px;
    line-height: 2.617rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
    color: var(--blue);
    user-select: none;
}

.language-available-item + .language-available-item {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.language-available-item + .language-available-item:last-of-type {
    border-bottom: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.language-available-item:hover {
    background-color: var(--gray100);
    color: var(--dark-blue);
}

.language-available .language-selected {
    color: var(--fontColor);
}

.selected-language {
    font-size: .85rem;
    font-weight: 600;
}

@keyframes drop-down {
    0% {
        transform: scaleY(0);
    }

    70% {
        transform: scaleY(1.05);
    }

    100% {
        transform: scaleY(1);
    }
}
