.last-block-container {
    min-height: 312px;
    margin-top: -16px;
    overflow: hidden;
}

.last-block-logo {
    width: 100px;
    margin-top: 56px;
    animation-duration: 1500ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    will-change: transform;
    opacity: 0;
}

.last-block-tagline {
    margin-top: 8px;
    animation-duration: 1500ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    will-change: transform;
    font-weight: 900;
    opacity: 0;
}

@media (max-width: 640px) {
    .last-block-container {
        margin-top: 32px;
    }
}

@keyframes last-block-logo-zoom-in {
    0% {
        opacity: 0;
        transform: scale(1);
    }
    50% {
        opacity: .95;
        transform: scale(1.6);
    }
    100% {
        opacity: 1;
        transform: scale(1.2);
    }
}

@keyframes last-block-tagline-zoom-in {
    0% {
        opacity: 0;
        transform: scale(1) translateY(8px);
    }
    70% {
        opacity: .95;
        transform: scale(2.2) translateY(28px);
    }
    100% {
        opacity: 1;
        transform: scale(1.6) translateY(24px);
    }
}