.block-subtitle {
    width: calc(100% / 6 * 4);
    margin: 8px auto 0 auto;
    line-height: 1.2;
}

.block-img {
    width: calc(100% / 6 * 5);
    position: relative;
    margin: 4px auto 0 auto;
}

.block-dummy {
    padding-top: 100%;
}   

.block-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    will-change: transform;
}

.block-soon {
    position: absolute;
    width: 50px;
    height: 32px;
    top: 8px;
    right: 8px;
    z-index: 90;
}

@media (max-width: 640px) {
    .block-header {
        margin-top: 48px;
    }

    .block-subtitle {
        width: calc(100% - 16px);
        margin: 12px auto 0 auto;
        max-width: 400px;
    }

    .block-img {
        width: 100%;
        position: relative;
        margin: 8px auto 0 auto;
    }

    .block-soon {
        right: -8px;
    }
}