footer {
    margin-top: 64px;
    display: flex;
    justify-content: space-between;
    border-top: .5px solid var(--separator);
    padding: 16px 16px 32px 16px;
    width: 100%;
    max-width: 800px;
}

.footer-copyright {
    color: var(--gray500);
}

.footer-socials {
    display: flex;
    gap: 32px;
}

@media (max-width: 640px) {
    footer {
        justify-content: center;
        flex-direction: column-reverse;
        text-align: center;
        padding: 14px 16px 16px 16px;
        margin: 32px -24px 0 -24px;
        border-top: 1px solid rgba(192, 192, 192, .5);
    }

    .footer-copyright {
        display: none;
    }

    .footer-socials {
        padding: 0 60px;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: space-between;

    }
}

@media (max-width: 320px) {
    .footer-socials {
        padding: 0 10%;
    }
}