.pager-container {
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
    height: 8px;
}

.pager-dot {
    height: 8px;
    border-radius: 4px;
    background-color: white;
    display: inline-block;
}

.pager-active-dot {
    opacity: 1;
    margin: 0 3px;
    width: 16px;
    transition: width 300ms ease-in-out;
}

.pager-inactive-dot {
    opacity: .75;
    width: 8px;
    margin: 0 4px;
    transition: width 300ms ease-in-out;
}