.cell {
    margin: 0;
    position: relative;
}

.-whole {
    width: 100%;
}

.-half {
    width: calc(50% - 8px);
}

.-four {
    width: calc(100% / 12 * 4);
}

.-eight {
    width: calc(100% / 12 * 8);
}

.-nine {
    width: calc(100% / 12 * 9);
    margin-left: auto;
    margin-right: auto;
}

.-ten {
    width: calc(100% / 12 * 10);
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 640px) {
    .-half,
    .-eight,
    .-nine,
    .-ten,
    .-four {
        width: 100%;
    }

    .-half + .-half,
    .-four {
        margin-top: 16px;
    }
}