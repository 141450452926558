.newsArticle {
    width: 800px;
    position: relative;
    margin: 0 auto;
}

@media (max-width: 640px) {
    .newsArticle {
        width: auto;
    }
}

/*@media (max-width: 800px) {*/
/*    .newsArticle{*/
/*        width: 700px;*/
/*    }*/
/*}*/

/*@media (max-width: 700px) {*/
/*    .newsArticle{*/
/*        width: 600px;*/
/*    }*/
/*}*/

/*@media (max-width: 600px) {*/
/*    .newsArticle{*/
/*        width: 500px;*/
/*    }*/
/*}*/

/*@media (max-width: 500px) {*/
/*    .newsArticle{*/
/*        width: 400px;*/
/*    }*/
/*}*/

/*@media (max-width: 400px) {*/
/*    .newsArticle{*/
/*        width: 300px;*/
/*    }*/
/*}*/
