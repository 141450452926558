.article {
    max-width: 600px;
    margin: 0 auto;
    overflow-x: visible;
    position: relative;
    overflow-y: hidden;
    /*height: 110%;*/
    /*padding-bottom: 100px;*/
}

@media (max-width: 700px) {
    .article {
        width: 600px;
    }
}

@media (max-width: 600px) {
    .article {
        width: 500px;
    }
}

@media (max-width: 500px) {
    .article {
        width: 400px;
    }
}

@media (max-width: 400px) {
    .article {
        width: 300px;
    }
}


.article-animation {
    width: 248px;
    height: 248px;
    position: fixed;
    top: 70px;
    /*z-index: 1341341;*/
    will-change: transform;
}

.left-animation {
    left: 238px;

}

.right-animation {
    right: -790px;
}

.article-container {
    margin: -248px 280px 0 280px;
}

.article-leed {
    width: calc(100% / 8 * 7);
    margin-top: 12px;
}

.article-body {
    margin-top: 16px;
}

.article-author {
    font-size: .875rem;
    color: var(--gray500);
}

.article-date {
    margin-top: 32px;
    font-size: .875rem;
    color: var(--gray500);
}

.article h2 {
    margin: 32px 0 12px 0;
}

.article h3 {
    margin: 24px 0 8px 0;
}

.article img,
.article video {
    margin-top: 8px;
    width: 100%;
}

blockquote {
    border-left: 5px solid var(--blue);
    padding: 4px 24px;
    margin: 12px 0;
}

.article-sticker {
    position: absolute;
    top: 5%;
    bottom: 25%;
    left: 15%;
    right: 15%;
    will-change: transform;
}

@media (max-width: 1200px) {
    .article-animation {
        display: none;
    }

    .article-container {
        margin: 0 280px 0 280px;
    }
}

@media (max-width: 640px) {
    .article {
        width: 100%;
        margin: 0 auto;
        overflow-x: hidden;
    }

    .article-animation-container {
        margin: 0;
    }

    .article-container {
        margin: 0;
    }

    .article-leed {
        width: 100%;
    }
}