.questions-categories {
    max-width: 600px;
    margin: 32px auto 0 auto;
    position: relative;
}

.questions-category {
    margin-top: 16px;
}

.questions-question {
    margin-top: 4px;
}

.questions-question-link {
    color: var(--gray800);
}