.additional-block-container {
    padding: 8px 0;
    margin-top: 32px;
    display: flex;
    gap: 16px;
}

.additional-block-container:before {
    content: ' ';
    display: block;
    width: 5px;
    background: var(--blue);
    border-radius: 5px;
    overflow: hidden;
    flex: 0 0 auto;
    margin: 0;
}

.additional-block-wrapper {
    margin-bottom: 2px;
}

.additional-block-news-container {
    margin-top: 8px;
}

.additional-block-news-container-active {
    text-decoration: underline;
}

.additional-block-news-container-active b {
    color: var(--fontColor);
    text-decoration: underline;

}

.additional-block-data {
    margin-top: 12px;
}

@media (max-width: 640px) {}