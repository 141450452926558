:root {
  --blue: #20A0ED;
  --dark-blue: #0F4A6E; /*blue hover*/
  --separator: #C0C0C0; /*separator*/
}

[data-theme='light'] {
  --background: #ffffff;
  --background-blur:  rgba(255,255,255,.8);
  --fontColor: #000000;
  --gray100: #F2F2F3; /*hover*/
  --gray300: #CBCBCF; /*color for placeholders*/
  --gray500: #7B8286; /*color for description*/
  --gray800: #2A3133; /*color for header*/
  --scrolBtnColor: #20A0ED;
  --scrolBtnSvgColor: #fff;
}

[data-theme='dark'] {
  background: rgb(30,30,30);
  --background-blur:  rgba(30,30,30,.8);
  --background: #1E1E1E;
  --fontColor: #ffffff;
  color: #FFFFFF;
  --gray100: #1a1a1a; /*hover*/
  --gray300: #404040; /*color for placeholders*/
  --gray500: #7B8286; /*color for description*/
  --gray800: #eaeaea; /*color for header*/
  --scrolBtnColor: #7badff;
  --scrolBtnSvgColor: #fff;

}

.borderRight blockquote {
  border-right: 5px solid var(--blue);
  border-left: none;
}

.borderLeft blockquote {
  border-left: 5px solid var(--blue);
  border-right: none;
}

/*@media (prefers-color-scheme: light) {*/
/*  * {*/
/*    background-color: rgb(255,255,255);*/
/*    color: black;*/
/*  }*/

/*  :root {*/
/*    --gray800: #2A3133;*/
/*  }*/

/*  .welcome-logo {*/
/*    background-color: transparent;*/
/*  }*/


/*}*/


/*@media (prefers-color-scheme: dark) {*/
/*  * {*/
/*    background-color: rgb(30,30,30);*/
/*  }*/

/*  :root {*/
/*    --gray800: #d9d9d9;*/
/*  }*/

/*  .welcome-logo {*/
/*    background-color: transparent;*/
/*  }*/
/*}*/




* {
  margin: 0;
  padding: 0;
  transition: background-color .2s;
}



a {
  text-decoration: none;
  color: var(--blue);
}

a:hover {
  text-decoration: underline;
  color: var(--dark-blue);
}

html, body {
  overflow-x: hidden;
  max-width: 100vw;
  position: relative;

}



img {
  background: none;
  max-width: 100%;
}

li {
  list-style: none;
}

main {
  min-height: calc(100vh - 216px);

}

video {
  max-width: 100%;
}

.-hidden {
  display: none;
}

.-error {
  color: red !important;
}

.-error input {
  border-left: 3px solid red;
}


.-margin-8 {
  margin-top: 8px !important;
}

.-margin-16 {
  margin-top: 16px !important;
}

.-margin-24 {
  margin-top: 24px !important;
}


/*main {*/
/*  max-width: 960px;*/
/*}*/

/*@media (max-width: 960px) {*/
/*  main {*/
/*    width: 900px;*/
/*  }*/
/*}*/

/*@media (max-width: 900px) {*/
/*  main {*/
/*    width: 800px;*/
/*  }*/
/*}*/

/*@media (max-width: 800px) {*/
/*  main {*/
/*    width: 700px;*/
/*  }*/
/*}*/

/*@media (max-width: 700px) {*/
/*  main {*/
/*    width: 600px;*/
/*  }*/
/*}*/


/*@media (max-width: 600px) {*/
/*  main {*/
/*    width: 500px;*/
/*  }*/
/*}*/

/*@media (max-width: 500px) {*/
/*  main {*/
/*    width: 400px;*/
/*  }*/
/*}*/

/*@media (max-width: 400px) {*/
/*  main {*/
/*    width: 350px;*/
/*  }*/
/*}*/

/*@media (max-width: 350px) {*/
/*  main {*/
/*      width: 300px;*/
/*  }*/
/*}*/