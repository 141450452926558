.blog-block-container {
    margin-top: 64px;
}

.blog-block-cell {
    margin: 0 32px;
}

.blog-block-title {
    margin-top: 16px;
}

@media (max-width: 640px) {
    .blog-block-cell {
        margin: 0;
    }
}