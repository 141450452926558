.animation-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 91;
}

.animation-container {
    transform: translateZ(0);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
