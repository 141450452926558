
.layout {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: 960px;
    padding-top: 48px; /* Navigation */
}

.-main-column {
    max-width: 800px;
    margin: 0 auto;
    overflow-x: visible;
    position: relative;
}

.-narrow-column {
    width: 600px;
    margin: 0 auto;
    overflow-x: visible;
    position: relative;
}

@media (max-width: 960px) {
    .layout {
        padding: 48px 16px 0 16px;
        width: calc(100% - 32px);
    }
}

@media (max-width: 640px) {
    .layout {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .-main-column {
        width: 100%;
        margin: 0 auto;
    }

    .-narrow-column {
        width: 100%;
        margin: 0 auto;
    }
}