.banner-container {
    width: 100%;
    aspect-ratio: 1.618;
    overflow: hidden;
    margin-top: 32px;
    position: relative;
}

.banner-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 24px !important;
    margin-top: 0 !important; 
    z-index: 2;
    filter: drop-shadow(0 0 8px rgba(0,0,0,.5));
}

.banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 0 !important;
}

.banner-active {
    animation-name: banner-zoom-out;
    animation-duration: .3s;
    animation-timing-function: ease-in-out;
}

.banner-active:hover {
    animation-name: banner-zoom;
    animation-duration: .15s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    cursor: pointer;
}

@keyframes banner-zoom {
    0% {transform: scale(1);}
    90% {transform: scale(1.022)}
    100% {transform: scale(1.02);}
}

@keyframes banner-zoom-out {
    0% {transform: scale(1.02);}
    100% {transform: scale(1);}
}



@media (max-width: 640px) {
    .banner-gallery {
        max-width: 76vw;
        max-height: 76vh;
        margin-top: 0 !important;
    }
}