.about-play-button-zoom-out {
    animation: play-button-zoom-out 500ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    will-change: transform;
}

.aboutContainer {
    width: 800px;
    margin: 0 auto;
    overflow-x: visible;
    position: relative;
}

@media (max-width: 800px) {
    .aboutContainer {
        max-width: 800px;
        width: auto;
    }
}


/*@media (max-width: 700px) {*/
/*    .aboutContainer {*/
/*        width: 600px;*/
/*    }*/
/*}*/

/*@media (max-width: 600px) {*/
/*    .aboutContainer {*/
/*        width: 500px;*/
/*    }*/
/*}*/

/*@media (max-width: 500px) {*/
/*    .aboutContainer {*/
/*        width: 400px;*/
/*    }*/
/*}*/

/*@media (max-width: 400px) {*/
/*    .aboutContainer {*/
/*        width: 300px;*/
/*    }*/
/*}*/




@keyframes play-button-zoom-out {
    0% {
        opacity: .75;
        transform: scale(1) rotate(0deg);
    }
    100% {        
        opacity: 0;
        transform: scale(.1) rotate(180deg);
    }
}